<template>
  <div class="main">
    <div class="content" v-if="orderData !== '{}'">
      <div class="title">
        <span>{{ $t('order.Details.493022-0') }}</span>
      </div>
      <div class="box">
        <div class="info-image">
          <el-image
            style="width: 80px; height: 80px"
            :src="`${orderData.productImageUrl}`"
            fit="fit"
          ></el-image>
        </div>
        <div class="basic-info-box">
          <div class="info-box">
            <div class="info-item">
              <span>{{ $t('order.Details.493022-1') }}{{ orderData.orderNum }} </span>
            </div>
            <div class="info-item">
              <span
                >{{ $t('order.Details.493022-2') }}
                <span v-if="isRefund">
                  <span v-if="refundType === 2">{{ $t('order.Details.493022-3') }}</span>
                  <span v-else-if="refundType === 1">{{ $t('order.Details.493022-4') }}</span>
                  <span v-else-if="refundType === 0">{{ $t('order.Details.493022-5') }}</span>
                  <!-- <span>{{ refundType === 2 ? $t('order.Details.493022-4') : $t('order.Details.493022-3') }}</span> -->
                </span>
                <span v-else>{{
                  $dict.orderState | dictFormat(orderData.shippingState)
                }}</span>
              </span>
            </div>
            <div class="info-item">
              <span>{{ $t('order.Details.493022-6') }}{{ orderData.createTime || "-" }}</span>
            </div>
            <div class="info-item">
              <span>{{ $t('order.Details.493022-7') }}{{ orderData.nickName }} </span>
            </div>
            <div class="info-item">
              <span>{{ $t('order.Details.493022-8') }}{{ orderData.payTime || "-" }}</span>
            </div>
            <div class="info-item">
              <span
                >{{ $t('order.Details.493022-9') }}{{
                  $dict.paymentType | dictFormat(orderData.paymentChannel)
                }}</span
              >
            </div>
            <div class="info-item">
              <span>{{ $t('order.Details.493022-10') }} {{ orderData.consigneeName }}</span>
            </div>
            <div class="info-item">
              <span>{{ $t('order.Details.493022-11') }} {{ orderData.consigneePhone }}</span>
            </div>
            <div class="info-item">
              <span
                >{{ $t('order.Details.493022-12') }}{{ orderData.province }}{{ orderData.city
                }}{{ orderData.county }}{{ orderData.detailed }}</span
              >
            </div>
            <div class="info-item">
              <span
                >{{ $t('order.Details.493022-13') }}{{
                  orderData.businessCheckDetail || "-"
                }}</span
              >
            </div>
            <div
              class="info-item"
              v-if="orderData.shippingState >= 4 && refundType === 0"
            >
              <span>{{ $t('order.Details.493022-14') }}{{ orderData.emsname || orderData.emsId }}</span>
              <span style="padding: 0 8px">{{ orderData.shipNumber }}</span>
              <template>
                <!-- <el-button
                  type="text"
                  size="small"
                  @click="logisticsOpen(orderData)"
                  >查看物流
                </el-button> -->
                <!-- <el-button
                  type="text"
                  size="small"
                  @click="logisticsRevise(orderData)"
                  >修改物流
                </el-button> -->
                <el-button
                  v-if="orderData.shippingState === 4"
                  size="small"
                  type="text"
                  @click="editWuliuInfo"
                >
                  {{ $t('order.Details.493022-15') }}
                </el-button>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="title">
        <span>{{ $t('order.Details.493022-16') }}</span>
      </div>
      <div>
        <el-table
          :data="orderData.items"
          show-summary
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }"
          style="width: 100%"
          border
        >
          <el-table-column prop="productId" :label="$t('order.Details.493022-17')" width="auto">
          </el-table-column>
          <el-table-column :label="$t('order.Details.493022-18')" width="auto" prop="productName">
            <template slot-scope="scope">
              <span
                style="color: #ff5000; cursor: pointer"
                @click="toGoodsDetails(scope.row)"
              >
                {{ orderData.productName }}
              </span>
             
            </template>
          </el-table-column>
          <el-table-column prop="typeName" :label="$t('order.Details.493022-19')" width="auto">
          </el-table-column>
          <el-table-column prop="price" :label="$t('order.Details.493022-20')" width="auto">
          </el-table-column>
          <el-table-column prop="quantity" :label="$t('order.Details.493022-21')" width="auto">
          </el-table-column>
          <el-table-column
            prop="productDetailGroupName"
            :label="$t('order.Details.493022-22')"
            width="auto"
          >
          </el-table-column>
          <el-table-column :label="$t('order.Details.493022-23')" prop="amount"> </el-table-column>
        </el-table>
      </div>
      <div class="title">
        <span>{{ $t('order.Details.493022-24') }}</span>
      </div>
      <div>
        <span>{{ orderData.buyerMessage }}</span>
      </div>
    </div>
    <!-- goodsDetalisVisible -->
    <el-dialog
      :title="$t('order.Details.493022-16')"
      :visible.sync="goodsDetalisVisible"
      :append-to-body="true"
    >
      <el-form ref="form" label-width="80px">
        <el-form-item :label="$t('order.Details.493022-25')">
          <div style="display: flex">
            <div
              v-for="(item, index) in goodsData.imageList"
              :key="index"
              class="shoplistimg"
            >
              <el-image
                fit="cover"
                style="width: 80px; height: 80px; margin-right: 4px"
                :src="item.imageUrl"
              ></el-image>
            </div>
          </div>
        </el-form-item>
        <el-form-item :label="$t('order.Details.493022-26')">
          <span>{{ goodsData.name }}</span>
        </el-form-item>
        <el-form-item :label="$t('order.Details.493022-27')">
          <span
            >{{ goodsData.productTypeParentName }}/{{
              goodsData.productTypeName
            }}</span
          >
        </el-form-item>
        <el-form-item :label="$t('order.Details.493022-28')">
          <span v-if="goodsData.isOne === 1">{{ $t('order.Details.493022-29') }}</span>
          <span v-else-if="goodsData.isOne === 2">{{ $t('order.Details.493022-30') }}</span>
        </el-form-item>
        <el-form-item :label="$t('order.Details.493022-22')">
          <el-table
            :data="goodsData.repositoryList"
            :header-cell-style="{ 'text-align': 'center' }"
            :cell-style="{ 'text-align': 'center' }"
            border
            style="width: 100%"
          >
            <el-table-column prop="productName" :label="$t('order.Details.493022-22')" width="auto">
            </el-table-column>
            <el-table-column :label="$t('order.Details.493022-20')" width="auto">
              <template slot-scope="scope">
                <span v-if="scope.row.price">$HK{{ scope.row.price }}</span>
                <span v-else>--</span>
              </template>
            </el-table-column>
            <el-table-column prop="count" :label="$t('order.Details.493022-31')"> </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item :label="$t('order.Details.493022-32')">
          <el-checkbox-group v-model="goodsData.deliveryType">
            <el-checkbox label="2" name="deliveryType" disabled
              >{{ $t('order.Details.493022-33') }}</el-checkbox
            >
            <el-checkbox label="1" name="deliveryType" disabled
              >{{ $t('order.Details.493022-34') }}</el-checkbox
            >
            <el-checkbox label="3" name="deliveryType" disabled
              >{{ $t('order.Details.493022-32') }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item :label="$t('order.Details.493022-35')">
          <div class="goods-details" v-html="goodsData.detail"></div>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 修改物流dialog -->
    <!-- 发货 -->
    <el-dialog
      :title="$t('order.Details.493022-15')"
      :visible.sync="deliverDialogVisible"
      width="30%"
      @close="deliverDialogClose"
      v-if="deliverDialogVisible"
      :modal="false"
    >
      <div>
        <el-form ref="deliverForm" :model="deliverForm" :rules="rules">
          <el-form-item>
            <p class="noticeTxt">{{ $t('order.Details.493022-36') }}$t('order.Details.493022-37')</p>
          </el-form-item>
          <el-form-item :label="$t('order.Details.493022-38')" prop="emsId" label-width="120px">
            <el-select
              v-model="deliverForm.emsId"
              :placeholder="$t('order.Details.493022-39')"
              style="width: 280px"
              filterable
              allow-create
            >
              <el-option
                v-for="item in logisticsList"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              >
              </el-option>
            </el-select>
            <el-button @click="addDomain" type="primary" class="maginBtn"
              >{{ $t('order.Details.493022-37') }}</el-button
            >
          </el-form-item>

          <el-form-item
            label-width="120px"
            v-for="(domain, index) in deliverForm.domains"
            :label="$t('order.Details.493022-40') + (index + 1)"
            :key="domain.key"
            :prop="'domains.' + index + '.value'"
            :rules="{
              required: true,
              message: $t('order.Details.493022-41'),
              trigger: 'blur',
            }"
          >
            <el-input v-model="domain.value" style="width:60%"></el-input
            ><el-button @click.prevent="removeDomain(domain)" class="maginBtn"
              >{{ $t('order.Details.493022-42') }}</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deliverDialogClose">{{ $t('order.Details.493022-43') }}</el-button>
        <el-button type="primary" @click="deliverSubmit">{{ $t('order.Details.493022-44') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    orderData: {
      type: Object,
      default: () => {},
    },
    isRefund: {
      type: Boolean,
      default: () => false, //是否是退款订单详情  默认全部订单
    },
    isSharedGoods: {},
    refundType: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      goodsDetalisVisible: false,
      goodsData: [],
      deliverDialogVisible: false, //发货弹窗
      logisticsList: [], //物流列表
      // 发货表单
      deliverForm: {
        emsId: null,
        emsNum: null,
        domains: [],
      },
      // 校验规则
      rules: {
        emsId: [
          {
            required: true,
            message: this.$t('order.Details.493022-45'),
            trigger: "change",
          },
        ],
        // domains: [
        //   {
        //     required: true,
        //     message: "请输入物流单号",
        //     trigger: "blur",
        //   },
        // ],
      },
    };
  },
  created() {
    this.getLogisticsList();
  },
  methods: {
    onSubmit() {
      console.log("submit!");
    },
    // 查看物流
    logisticsOpen(data) {
      this.$emit("logisticsOpen");
    },
    logisticsRevise(data) {
      this.$emit("logisticsRevise");
    },
    toGoodsDetails(item) {
      
      let resData = {
        productId: item.productId,
      };
      this.$api.goods.getSpecInfoById(resData).then((res) => {
        res.data.deliveryType = res.data.deliveryType.split(",");

        this.goodsData = res.data ? res.data : [];
        this.goodsDetalisVisible = true;
      });
    },

    /* 编辑物流 */
    editWuliuInfo() {
      this.deliverForm.domains = [];
      // 打开编辑物流弹层
      this.deliverDialogVisible = true;
      // 赋值
      this.deliverForm.emsId = this.orderData.emsname || this.orderData.emsId;
      // 物流单号
      let str = this.orderData.shipNumber;
      let orderArr;
      if (str) {
        orderArr = str.split(",");
        orderArr.forEach((item) => {
          this.deliverForm.domains.push({
            value: item,
            key: item,
          });
        });
      } else {
        orderArr = [];
      }
    },
    /* 编辑物流弹层关闭 */
    deliverDialogClose() {
      // 发货弹窗关闭
      // this.$refs["deliverForm"].resetFields();
      Object.keys(this.deliverForm).forEach((item) => {
        this.deliverForm[item] = null;
      });
      this.deliverForm.domains = [];
      this.deliverDialogVisible = false;
    },

    /* 提交新物流表单 */
    // 发货提交
    deliverSubmit() {
      this.$refs["deliverForm"].validate(async (valid) => {
        if (valid) {
          let emsNumArrat = [];
          this.deliverForm.domains.forEach((item) => {
            emsNumArrat.push(item.value);
          });
          let resData = {
            emsId: this.deliverForm.emsId,
            emsNum: emsNumArrat.toString(),
            orderId: this.orderData.id,
          };
          if (!resData.emsNum) {
            this.$message({
              type: "info",
              message: this.$t('order.Details.493022-46'),
            });
            return;
          }
          await this.$api.order.confirmShopOrder(resData).then((res) => {
            if (res.success) {
              this.$message({
                type: "success",
                message: this.$t('order.Details.493022-47'),
              });
              // 重新加载数据
              this.$api.order
                .orderDetails({
                  id: this.orderData.id,
                  isSharedGoods: this.isSharedGoods,
                })
                .then((res) => {
                  this.$emit("update:orderData", res.data);
                  this.deliverDialogClose();
                });
            } else {
              this.$message.error(res.message);
            }
            this.deliverDialogClose();
          });
        }
      });
    },

    /* 获取物流公司列表 */
    getLogisticsList() {
      this.$api.order.slbemsEmsList().then((res) => {
        // console.log(res);
        if (res.success) {
          this.logisticsList = res.data ? res.data : [];
        }
      });
    },

    //新增多个物流单号
    addDomain() {
      this.deliverForm.domains.push({
        value: "",
        key: Date.now(),
      });
    },

    // 删除物流单号
    removeDomain(item) {
      var index = this.deliverForm.domains.indexOf(item);
      if (index !== -1) {
        this.deliverForm.domains.splice(index, 1);
      }
    },
    test(val){
      console.log(this.orderData,'val')
    }
  },
};
</script>

<style lang="scss" scoped>
.main {
  height: calc(100vh - 72px);
  padding: 32px;
  overflow-y: auto;
  background: #c0c4cc;
}
.noticeTxt {
  color: #ff5000;
}
.content {
  background: #fff;
  padding: 24px;
  border-radius: 4px;

  .title {
    font-weight: bold;
    margin: 16px 0;
    color: #1f2f3d;
    font-size: 14px;
  }
}
.basic-info-box {
  display: flex;
  align-items: center;

  .info-box {
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .info-item {
      margin: 8px 0;
      width: 45%;
    }
  }
}
// .goods-details >>> .img {
//   max-width: 100%;
//   height: auto;
// }
.maginBtn {
  margin-left: 20px;
}
.goods-details {
  ::v-deep img {
    width: 100%;
  }
}
</style>
